<template>
  <div class="operate">
    <a-timeline>
      <a-timeline-item>Create a services site 2015-09-01</a-timeline-item>
      <a-timeline-item>Solve initial network problems 2015-09-01</a-timeline-item>
      <a-timeline-item color="red">
        <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;"/>
        Technical testing 2015-09-01
      </a-timeline-item>
      <a-timeline-item>Network problems being solved 2015-09-01</a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
  export default {
    name: 'operate',
    components: {},
    mounted: function () {
    },
    data: function () {
      return {}
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .operate {
    padding: 0 $container-padding;
  }
</style>
